import { render, staticRenderFns } from "./CustomVueTable.vue?vue&type=template&id=968db014&scoped=true&"
import script from "./CustomVueTable.vue?vue&type=script&lang=js&"
export * from "./CustomVueTable.vue?vue&type=script&lang=js&"
import style0 from "./CustomVueTable.vue?vue&type=style&index=0&id=968db014&scoped=true&lang=css&"
import style1 from "./CustomVueTable.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "968db014",
  null
  
)

export default component.exports