<template>
  <div class="row">
    <div class="col col-12" v-if="showFilter" >
      <div v-if="openedFilters" class="">
        <h5 class="box-title text-primary">FILTROS</h5>
        <form action="javascript:;">
          <div class="row">
            <div  v-for="(field, index) in filterFields" v-bind:key="index" class="pl-0 col-sm-4 form-group mb-4">
              <label class="active">{{ field.title }}</label>
              <input v-if="field.type === 'text'" class="form-control"
                     type="text"
                     :name="field.name"
                     v-model="filterFields[index].value"
                     v-on:input="updateValue"
                     :placeholder="field.title">

              <select v-else-if="field.type === 'boolean'" class="form-control" v-model="field.value">
                <option value=""></option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>

              <select v-else-if="field.type === 'options'" class="form-control" v-model="field.value">
                <option v-for="(option, index) in field.options" v-bind:key="index"
                        :value="index">
                  {{ option }}
                </option>

              </select>

            </div>
          </div>
          <div class="form-group pull-right">
            <button class="btn btn-link" type="reset" v-on:click="onResetFilter"> Limpar filtros</button>
            <button class="btn btn-primary mr-2"  v-on:click="onFilter" type="submit">Filtrar</button>
          </div>
        </form>
      </div>
      <div v-else class="">
        <div class="form-group pull-right">
          <div class="btn btn-outline-light" v-on:click="onToggleShowFilters">
            <i class="fa fa-filter"></i> Filtros
          </div>
        </div>
      </div>
    </div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <label class="header-title">{{$t(label)}}</label>
    <div class="col-12 ml-0 pl-0" v-if="description" v-html="description">
    </div>
    <div class="table-responsive">
      <vuetable ref="customVuetable"
                :noDataTemplate="this.$i18n.t('noting to result')"
                :http-method="httpMethod"
                :api-url="apiUrl"
                :fields="fields"
                :sort-order="sortOrder"
                :css="css.table"
                pagination-path="pagination"
                :per-page="perPage"
                :multi-sort="multiSort"
                :append-params="getFilters"
                :transform="transformData"
                :multi-sort-key="multiSortKey"
                :http-options="getHttpOptions"
                :track-by="trackBy"
                :row-class="rowClass"
                :detail-row-component="detailRowComponent"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:loading="onLoading"
                @vuetable:loaded="onLoaded"
                @vuetable:load-error="handleLoadError"
                @vuetable:cell-clicked="onRowClicked">
                <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
                <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>
      </vuetable>

    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <div v-if="paginationData.total > 1">
          Mostrando {{ $util.formatNumber(paginationData.from, 0) }} até {{ $util.formatNumber(getToNumber, 0) }} de {{ $util.formatNumber(paginationData.total, 0) }}
            <span v-if="totalLabel" v-html="totalLabel"></span>
            <span v-if="paginationData.hasOwnProperty('total_clientes')">
               - Clientes: {{ paginationData.total_clientes }}
            </span>

          </div>
        </div>
        <div class="col-md-6">
          <vuetable-pagination ref="pagination"
                               :css="css.pagination"
                               @vuetable-pagination:change-page="onChangePage"
          ></vuetable-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import Vuetable, { VuetablePagination } from 'vuetable-2'
// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
// import { totalmem } from 'os';
export default {
  name: 'CustomVueTable',
  data () {
    return {
      fullPage: true,
      isLoading: false,
      openedFilters: false,
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      },
      paginationData: {
        total: 0,
        per_page: 20,
        current_page: 1,
        last_page: 1,
        from: 1,
        to: 20
      }
    }
  },
  props: {
    totalLabel: {
      type: String,
      required: false,
      default: null
    },
    filterFields: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      required: false
    },
    // Vue Table config
    apiUrl: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    transformData: {
      type: Function,
      required: false
    },
    sortOrder: {
      type: Array,
      default () {
        return []
      }
    },
    httpMethod: {
      type: String,
      default: 'get',
      validator: (value) => {
        return ['get', 'post'].indexOf(value) > -1
      }
    },
    perPage: {
      type: Number,
      default: 20
    },
    multiSort: {
      type: Boolean,
      default: true
    },
    /*
     * physical key that will trigger multi-sort option
     * possible values: 'alt', 'ctrl', 'meta', 'shift'
     * 'ctrl' might not work as expected on Mac
     */
    multiSortKey: {
      type: String,
      default: 'shift'
    },
    appendParams: {
      type: Object,
      default () {
        return {}
      }
    },
    httpOptions: {
      type: Object
    },
    trackBy: {
      type: String,
      default: 'id'
    },
    rowClass: {
      type: [String, Function],
      default: ''
    },
    detailRowComponent: {
      type: [String, Object],
      default: '',
      required: false
    }
    // End Vue Table config
  },
  components: {
    Vuetable,
    VuetablePagination,
    Loading
  },
  methods: {
    onToggleShowFilters () {
      this.openedFilters = !this.openedFilters
    },
    onFilter () {
      setTimeout(() => {
        this.$refs.customVuetable.refresh()
      }, 400)
    },
    onResetFilter () {
      for (let i = 0; i < this.filterFields.length; i++) {
        this.filterFields[i].value = null
      }

      this.openedFilters = false

      // Adicionado pois ao resetar na primeira vez não estava limpando -
      setTimeout(() => {
        this.onFilter()
      }, 400)
    },
    updateValue (event) {
      this.$emit('input', { ...this.filterFields })
    },
    onPaginationData (paginationData) {
      this.paginationData = paginationData
      this.$refs.pagination.setPaginationData(paginationData)
      this.$emit('on-pagination-data', paginationData)
    },
    onChangePage (page) {
      this.$refs.customVuetable.changePage(page)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      // this.$jQuery('table.table').reflowTable()
      if (this.$route.query.process) {
        if (this.$refs.customVuetable.$data.tableData) {
          if (this.$refs.customVuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.customVuetable.$data.tableData[0] })
          }
        }
      }
    },
    onRowClicked (row) {
      this.$emit('row-clicked', row)
    },
    handleLoadError (response) {
    },
    // End Load Contact Tables
    // End Format Table functions
    refresh () {
      this.$refs.customVuetable.refresh()
    }
  },
  computed: {
    getFilters () {
      if (this.filterFields) {
        let appliedFilters = Object.assign({}, this.appendParams)
        let tableFilters = {}

        for (let filterIndex in this.filterFields) {
          let filter = this.filterFields[filterIndex]
          if (filter.value && filter.value !== '') {
            tableFilters[filter.name] = { type: filter.type, value: filter.value }
          }
        }

        if (Object.keys(tableFilters).length > 0) {
          appliedFilters['tableFilters'] = JSON.stringify(tableFilters)
        }

        return appliedFilters
      }

      return this.appendParams
    },
    showFilter () {
      return this.filterFields.length > 0
    },
    getToNumber () {
      if (this.paginationData.to > this.paginationData.total) {
        return this.paginationData.total
      }

      return this.paginationData.to
    },
    getHttpOptions () {
      let _this = this

      // If passed use passed params
      if (_this.httpOptions) {
        return _this.httpOptions
      }

      // Else use default http options
      return {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      }
    }
  },
  watch: {
    appendParams: {
      handler () {
      },
      deep: true
    }
  }
}
</script>

<style scoped>
  .table-responsive {
    padding: 0px;
  }

  .table-responsive {
    max-height: none !important;
    overflow: hidden;
    overflow-x: auto;
  }
</style>

<style>
  .table-clickable tbody tr.inativo:hover,
  .table-clickable tbody tr.inativo:hover td,
  .table-clickable tbody tr.inativo:hover td span {
    cursor: not-allowed;
  }

  tr.inativo button.btn.btn-link i{
    color: #b8b8c6 !important;
  }

  tr.inativo:hover {
    background-color: #fdfdfd;
    color: #b8b8c6;
  }

  tr.inativo {
    background-color: #fdfdfd;
    color: #b8b8c6
  }

  tr.inativo:hover {
    background-color: #efefef;
  }
</style>
